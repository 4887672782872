import { render, staticRenderFns } from "./Checkings.vue?vue&type=template&id=23408c65&scoped=true"
import script from "./Checkings.vue?vue&type=script&lang=js"
export * from "./Checkings.vue?vue&type=script&lang=js"
import style0 from "./Checkings.vue?vue&type=style&index=0&id=23408c65&prod&lange=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23408c65",
  null
  
)

export default component.exports