<template>
  <div class="checking_list">
    <div style="margin-top: 5px; margin-bottom: 5px">
      <back-container link="/checkingbox"></back-container>
    </div>

    <checking-filter></checking-filter>

    <div class="row center">
      <div class="card card-content">
        <div
          class="fixed-table-container"
          style="margin-top: 0px; padding-top: 0px"
        >
          <table class="app-table">
            <thead>
              <tr>
                <th>Raw Data</th>
                <th>Total Count : {{ checking_count }}</th>
                <th colspan="6">Filter By {{ filter_type }}</th>
                <th>
                  <button
                    class="btn red darken-3 statusButton"
                    @click="deletePatients"
                  >
                    Delete
                  </button>
                </th>
                <th>
                  <button
                    class="btn green darken-3 statusButton"
                    @click="deletePatients"
                  >
                    Continue
                  </button>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>No</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Age</th>
                <th>Submitted Date</th>
                <th>Volunteer</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(checking, index) in patients" :key="index">
                <td>
                  <input
                    type="checkbox"
                    :checked="checking.checked"
                    @change="handleCheckboxChange($event, checking)"
                  />
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ checking.name }}</td>
                <td>{{ checking.phone }}</td>
                <td>{{ checking.age }}</td>
                <td>
                  {{
                    moment(checking.created_at, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )
                  }}
                </td>
                <td>{{ checking.volunteer ? checking.volunteer.name : '' }}</td>
                <td>
                  <router-link
                    :to="{ name: 'PatientEdit', params: { id: checking.id, 'tptStatus': 0 } }"
                    class="btn blue darken-3"
                    >Edit</router-link
                  >
                </td>
                <td>
                  <button
                    @click="showDetail(checking)"
                    class="btn blue darken-3"
                  >
                    Detail
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row center" style="margin-top: 10px">
          <loading v-if="loading"></loading>
        </div>
        <div
          class="row center"
          v-if="!loading && checkings.length < checking_count"
        >
          <button class="btn blue darken-2" @click="loadMore">Load More</button>
        </div>
      </div>
    </div>
    <detail
      @close="detailShow = false"
      v-bind:show="detailShow"
      v-bind:detailData="detailData"
    ></detail>
  </div>
</template>

<script>
import StatusBox from "@/components/Checkings/StatusBox.vue";
import Loading from "@/components/Partials/Loading.vue";
import Detail from "@/components/Checkings/Checking/Detail.vue";
import CheckingFilter from "@/components/Checkings/Checking/CheckingFilter.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";
import { getFilterType } from "./../../misc/filtertype.js";

export default {
  name: "Checkings",
  data() {
    return {
      search: "",
      detailShow: false,
      datefilterShow: false,
      detailData: null,
      selectedIds: [],
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    this.$store.dispatch("clearCheckings");
    this.getData();
  },
  methods: {
    handleCheckboxChange(event, checking) {
      if (event.target.checked) {
        this.selectedIds.push(checking.id); 
      } else {
        const index = this.selectedIds.indexOf(checking.id);
        if (index !== -1) {
          this.selectedIds.splice(index, 1); 
        }
      }
    },
    deletePatients(){
      if(this.selectedIds.length > 0){
        this.$store.dispatch("deleteAllCheck", {ids: this.selectedIds});
      }
      return;
    },
    loadMore() {
      this.getData(true);
    },

    getData(load = false) {
      var data = {
        lastid: 0,
        load: load,
      };

      if (this.checkings.length > 0) {
        data.lastid = this.checkings.length;
      }

      if (this.$route.params.search) {
        data.search = this.$route.params.search;
      }

      if (
        (this.$route.query.startdate && this.$route.query.enddate) ||
        (this.$route.query.code && this.$route.query.year) ||
        this.$route.query.gender ||
        this.$route.query.age ||
        this.$route.query.status
      ) {
        if (this.$route.query.startdate && this.$route.query.enddate) {
          data.startdate = this.$route.query.startdate;
          data.enddate = this.$route.query.enddate;
        }

        if (this.$route.query.code && this.$route.query.year) {
          data.code = this.$route.query.code;
          data.year = this.$route.query.year;
        }

        if (this.$route.query.age) {
          data.age = this.$route.query.age;
        }

        if (this.$route.query.gender) {
          data.gender = this.$route.query.gender;
        }

        if (this.$route.query.status) {
          data.status = this.$route.query.status;
        }

        this.datefilterShow = false;
      }

      data.tptStatus = 0;

      if (!data.load) {
        data.lastid = 0;
      }

      this.$store.dispatch("getCheckings", data);
    },

    showDetail(value) {
      this.detailShow = true;
      this.detailData = value;
    },
  },
  components: {
    loading: Loading,
    statusbox: StatusBox,
    "back-container": BackContainer,
    detail: Detail,
    "checking-filter": CheckingFilter,
  },
  computed: {
    patients() {
      return this.$store.getters.checkings.map((checking) => {
        return { ...checking, checked: false };
      });
    },
    checkings() {
      return this.$store.getters.checkings;
    },
    counts() {
      return this.$store.getters.counts;
    },
    loading() {
      return this.$store.getters.loading;
    },
    checking_count() {
      return this.$store.getters.checking_count;
    },
    filter_type() {
      return getFilterType(this.$route);
    },
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lange="scss" scoped>
.checking_list {
  padding-left: 20px;
  padding-right: 20px;
}

.checking_list .card-content {
  padding: 10px;
}

.card {
  background: var(--card-background-color) !important;
}

.checking_list .fixed-table-container {
  margin-top: 40px;
  max-width: 100%;
  max-height: 530px;
  overflow: scroll;
  position: relative;
}

.checking_list .fixed-table-container table {
  position: relative;
  border-collapse: collapse;
  white-space: nowrap;
}

.checking_list .fixed-table-container td,
.fixed-table-container th {
  padding: 20px;
}

.checking_list .fixed-table-container thead{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset;
  opacity: 1;
  pointer-events: all;
}
</style>
